import { useState, Fragment, useEffect } from "react";
import { NavLink as RouterNavLink, useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoginButton } from "./buttons/login-button";
import logo from "../assets/images/brightloop-logo-web.svg"
import { UserProfileImage, useUserContext } from "../features/users";
import ClientProvider from "../modules/ClientProvider";
import Membership from "../features/users/types/Membership";

import {
    AppBar,
    Toolbar,
    Button,
    Menu,
    MenuItem,
    Box,
    Typography,
    IconButton,
    Stack,
    styled,
    Avatar,
} from "@mui/material";

import { useAuth0, User } from "@auth0/auth0-react";
import { getAllDataForSelf, getInvitation } from "../core/services/gutcheck-api.service";
import { useDealContext } from "../features/deal";

interface NavState {
    fetched: boolean,
    user: any,
    currentOrg: any,
    currentOrgTag: string,
    currentProject: any,
    currentProjectTag: string,
    userRole: string,
    currentOrgLogo: string,
    currentProjectLogo: string,
    surveyLink: any
};

const initState: NavState = {
    fetched: false,
    user: null,
    currentOrg: null,
    currentOrgTag: "",
    currentProject: null,
    currentProjectTag: "",
    userRole: "",
    currentOrgLogo: "",
    currentProjectLogo: "",
    surveyLink: null
};

interface DisplayLogoProps {
    state: NavState;
    isAuthenticated: boolean;
    hideUser: boolean;
}

interface DisplayCompanyLogoProps {
    state: NavState;
    isAuthenticated: boolean;
    hideUser: boolean;
}

const MenuItemOrgSelector = styled(MenuItem)(({ theme }) => ({
    "&.nav-dropdown-org-selector": {
        backgroundColor: "#f0f8ff",
        "& a": {
            width: "100%",
            display: "block",
            color: "inherit",
            textDecoration: "none",
        }
    },
    "&.has-link": {
        padding: 0,
    }
}));

const DisplayLogo = ({ state, isAuthenticated, hideUser }: DisplayLogoProps) => {
    const organization = state.currentOrg;

    const logoUrl = organization?.logo_uploaded ? `https://[someurl]` : organization?.logo_url;
    const link = organization && organization.tag ? `/o/${organization?.tag}` : "/";

    if (document.location.pathname.indexOf("/e/") === 0) {

        if (!state.fetched || state.surveyLink?.passthrough) {
            return <></>;
        }

        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box key="1">
                    <img className="nav-logo-img" src={logo.toString()} />
                </Box>
            </Box>
        );
    }

    else if (hideUser && !state.fetched) {
        return <></>;
    } else if (hideUser && state.fetched) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box key="1">
                    <img className="nav-logo-img" src={logoUrl && logoUrl != "" ? logoUrl : logo} />
                </Box>
            </Box>
        );
    } else if (state.userRole === "guest") {
        return (
            <Box sx={{
                marginRight: "auto",
                display: "flex",
                flexDirection: "row",
                gap: "10px"
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box key="1">
                        <RouterNavLink to={link} style={{ textDecoration: 'none' }}>
                            <img className="nav-logo-img" src={logoUrl && logoUrl != "" ? logoUrl : logo} />
                        </RouterNavLink>
                    </Box>
                </Box>
                <DisplayCompanyLogo state={state} isAuthenticated={isAuthenticated} hideUser={hideUser} />
            </Box>
        )
    }

    return (
        <Box sx={{
            marginRight: "auto",
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            maxHeight: '30px'
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Box key="1">
                    {/* Replace tag={RouterNavLink} with component={RouterNavLink} */}
                    <RouterNavLink
                        to={isAuthenticated ? link : "/"}
                        style={{ textDecoration: 'none' }}
                    >
                        <Box component="img" className="nav-logo-img" src={logoUrl && logoUrl != "" ? logoUrl : logo} />
                    </RouterNavLink>
                </Box>
            </Box>
            <DisplayCompanyLogo state={state} isAuthenticated={isAuthenticated} hideUser={hideUser} />
        </Box>
    )
}

const DisplayCompanyLogo = ({ state, isAuthenticated, hideUser }: DisplayCompanyLogoProps) => {

    const { dealContext, setDealContext } = useDealContext();
    const projectTagMatcher = new RegExp(`${dealContext.project?.tag}`);

    let companyName = "";
    let companyLogo = "";


    if (projectTagMatcher.test(window.location.pathname)) {
        companyLogo = dealContext.project?.company?.logo_url && dealContext.project?.company?.logo_url !== "" ? dealContext.project?.company?.logo_url :
            dealContext.project?.logo_url && dealContext.project?.logo_url !== "" ? dealContext.project?.logo_url :
                "";

        companyName = dealContext.project?.company?.name && dealContext.project?.company?.name !== "" ? dealContext.project?.company?.name : dealContext.project?.name;
    } else if (state.currentProject) {
        companyLogo = state.currentProject?.company?.logo_url && state.currentProject?.company?.logo_url !== "" ? state.currentProject?.company?.logo_url :
            state.currentProject?.logo_url && state.currentProject?.logo_url !== "" ? state.currentProject?.logo_url :
                "";

        companyName = state.currentProject?.company?.name && state.currentProject?.company?.name !== "" ? state.currentProject?.company?.name : state.currentProject?.name;
    }

    if (companyLogo && companyLogo !== "") {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box key="1" sx={{ display: "flex", flexDirection: "row", gap: "10px", height: "100%", alignItems: "center" }}>
                    <Typography>for</Typography>
                    <Box sx={{ height: "100%" }}><img className="nav-logo-img" src={companyLogo} /></Box>
                </Box>
            </Box>
        );
    } else if (companyName && companyName !== "") {
        return (<Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box key="1" sx={{ display: "flex", flexDirection: "row", gap: "10px", height: "100%", alignItems: "center" }}>
                <Typography>for</Typography>
                <Typography sx={{ fontWeight: 700, fontSize: "1.5rem" }}>{companyName}</Typography>
            </Box>
        </Box>
        );
    }

    return <></>;
}

const NavBar = ({ hideUser = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [state, setState] = useState(initState);

    const [searchParams, setSearchParams] = useSearchParams();

    const { userContext, setUserContext } = useUserContext();
    const { dealContext, setDealContext } = useDealContext();
    const {
        user,
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        getAccessTokenSilently,
        logout,
    } = useAuth0();
    const toggle = () => setIsOpen(!isOpen);

    const location = useLocation();

    const forceRefresh = searchParams.has("refresh");

    useEffect(() => {
        let isMounted = true;

        // Grab organization tag from URL if it's there
        const newOrganizationTag = location.pathname.indexOf("/o/") === 0 ? location.pathname.replace(/^\/o\//, "").replace(/\/.*/, "") : "";
        const newProjectTag = location.pathname.indexOf("/project/") > 0 ? location.pathname.replace(/^.*\/project\//, "").replace(/\?.*/, "") : "";

        const fetchProjectData = async () => {

            if (!isMounted) {
                return;
            }

            if (userContext.dbUser) {
                const prevOrganization = state.currentOrg;
                const prevProject = state.currentProject;
                let currentOrg = null;
                let currentProject = null;
                let userRole = "";

                if (location.pathname.indexOf("/admin") === 0) {
                    userRole = "admin";
                    currentOrg = null;
                    currentProject = null;
                } else if (userContext.organizationMemberships?.length > 0) {
                    const currentOrganizationTag = newOrganizationTag ? newOrganizationTag : prevOrganization?.tag ? prevOrganization?.tag : userContext.organizationMemberships[0].organization.tag;

                    currentOrg = userContext.organizationMemberships?.find((organizationMembership: Membership) => organizationMembership.organization?.tag === currentOrganizationTag)?.organization;
                    userRole = "contributor";
                } else if (userContext.projectMemberships?.length > 0) {
                    const currentProjectTag = newProjectTag ? newProjectTag : prevProject?.tag ? prevProject?.tag : userContext.projectMemberships[0].project.tag;
                    currentProject = userContext.projectMemberships?.find((projectMembership: Membership) => projectMembership.project.tag === currentProjectTag);
                    currentOrg = currentProject?.project?.organization;
                    userRole = "guest";
                }

                if (forceRefresh) {
                    searchParams.delete("refresh");
                    setSearchParams(searchParams);
                }
                setState({
                    ...state,
                    fetched: userContext.fetched,
                    user: userContext.dbUser,
                    currentOrg: currentOrg,
                    currentOrgTag: currentOrg?.tag ?? "",
                    currentProject: currentProject,
                    currentProjectTag: currentProject?.tag ?? "",
                    userRole: userRole
                });
            } else if (userContext.fetched && !userContext.dbUser) {
                setState({
                    ...state,
                    fetched: userContext.fetched,
                    user: null,
                    currentOrg: null,
                    currentOrgTag: "",
                    currentProject: null,
                    currentProjectTag: "",
                    userRole: ""
                });
            }

        };

        const fetchInvitationData = async () => {
            const invitationCode = location.pathname.replace(/^\/invitation\//, "").replace(/\/.*/, "");
            const { data, error } = await getInvitation(invitationCode);
            if (data.invitation && data.invitation.organization) {
                const organization = data.invitation.organization;
                setState({
                    ...state,
                    fetched: true,
                    user: null,
                    currentOrg: organization,
                    currentOrgTag: organization.tag
                });
            }
        }

        const fetchShareLinkData = async () => {
            const shareLink = location.pathname.replace(/^\/guest\//, "").replace(/\/.*/, "");
            const { data, error } = await ClientProvider.provideGuestClient().getShareLinkData(shareLink);
            if (data?.project) {
                const project = data.project;
                const organization = project.organization;
                setState({
                    ...state,
                    fetched: true,
                    user: null,
                    currentOrg: organization,
                    currentOrgTag: organization.tag,
                    currentProject: project,
                    currentProjectTag: project.tag,
                    userRole: "guest"
                });
            }
        }

        // If the user is authenticated...
        // We only load their data if we haven't done it yet or the new org isn't the same as the current org

        if (location.pathname.indexOf("invitation/") > 0) {
            fetchInvitationData();
        } else if (location.pathname.indexOf("guest/") > 0) {
            fetchShareLinkData();
        } else if ((!isLoading && isAuthenticated) && (!state.fetched || newOrganizationTag !== state.currentOrg?.tag || searchParams.has("refresh") || forceRefresh)) {
            fetchProjectData();
        } else if (document.location.pathname.indexOf("/e/") === 0 && dealContext.fetched && dealContext.project) {
            setState({
                ...state,
                fetched: dealContext.fetched,
                user: null,
                currentOrg: dealContext.project?.organization,
                currentOrgTag: dealContext.project?.organization?.tag,
                currentProject: dealContext.project,
                currentProjectTag: dealContext.project?.tag,
                userRole: dealContext.role,
                surveyLink: dealContext.surveyLink
            });
        } else if (!isLoading && !isAuthenticated && document.location.pathname.indexOf("/e/") < 0) {
            setState({
                ...state,
                fetched: true,
                user: null,
                currentOrg: null,
                currentOrgTag: "",
                currentProject: null,
                currentProjectTag: "",
                userRole: ""
            });
        }

        return () => {
            isMounted = false;
        };
    }, [userContext, isAuthenticated, isLoading, location, forceRefresh, dealContext]);

    if (isAuthenticated && !state.fetched) return <Fragment />

    if (location.pathname.indexOf("invitation/") > 0 && !state.fetched) return <Fragment />

    if (location.pathname.indexOf("guest/") > 0 && !state.fetched) return <Fragment />

    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            }
        });

    if (hideUser) {
        return (
            <AppBar position="static" color="default" elevation={0} sx={{ backgroundColor: '#f8f9fa', position: 'absolute', top: 0, height: '70px', zIndex: 1200, boxShadow: '0 0 5px #c9c9c9', width: '100%' }}>
                <Toolbar>
                    <DisplayLogo state={state} isAuthenticated={isAuthenticated} hideUser={hideUser} />
                </Toolbar>
            </AppBar>
        );

    }

    const organizationMemberships = (userContext?.organizationMemberships || []).sort((a: Membership, b: Membership) => {
        const nameComparison = a.organization.name.localeCompare(b.organization.name);
        if (nameComparison === 0) {
            return a.organization.id - b.organization.id;
        }
        return nameComparison;
    });

    return (
        <AppBar position="static" color="default" elevation={0} sx={{ backgroundColor: '#f8f9fa', position: 'absolute', top: 0, height: '70px', zIndex: 1200, boxShadow: '0 0 5px #c9c9c9', width: '100%' }}>
            <Toolbar>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    {state.fetched && <DisplayLogo state={state} isAuthenticated={isAuthenticated} hideUser={hideUser} />}
                    <Stack direction="row" spacing={2} alignItems="center">
                        {!hideUser && !isAuthenticated && !(location.pathname.indexOf("invitation/") > 0) && (
                            <Fragment>
                                <Box key="2">
                                    <LoginButton />
                                </Box>
                            </Fragment>
                        )}
                        {!hideUser && isAuthenticated && user && (
                            <Box className="navbar-profile-info" sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <Typography className="nav-company-name">{state.currentOrg?.name}</Typography>
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    className="dropdown-toggle"
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "transparent",
                                            cursor: "pointer"
                                        }
                                    }}
                                >
                                    <UserProfileImage user={userContext.dbUser} sx={{ cursor: "pointer" }} />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    sx={{
                                        zIndex: 2001,
                                        "& li": { padding: 0 },
                                        "& li a": { 
                                            color: "#000000", 
                                            textDecoration: "none", 
                                            padding: "7px 25px",
                                            width: "100%",
                                            display: "block",
                                            "&:hover": { color: "#000000", textDecoration: "none" } 
                                        },
                                        "& li.active a": { color: "#ffffff" }
                                    }}
                                    className="nav-dropdown-menu"
                                >
                                    {/* User name - no link needed */}
                                    <MenuItem sx={{ fontWeight: 'bold', backgroundColor: 'white', padding: '7px 25px' }}>{user.name}</MenuItem>

                                    {/* Organization memberships */}
                                    {userContext && organizationMemberships.map((organizationMembership: Membership) => (
                                        <MenuItemOrgSelector
                                            key={organizationMembership.organization.tag}
                                            className={`nav-dropdown-org-selector has-link ${state.currentOrgTag === organizationMembership.organization.tag ? "active" : ""}`}
                                        >
                                            <RouterNavLink to={`/o/${organizationMembership.organization.tag}`}>
                                                {organizationMembership.organization.name}
                                            </RouterNavLink>
                                        </MenuItemOrgSelector>
                                    ))}

                                    {/* Profile link */}
                                    <MenuItem className="dropdown-profile has-link">
                                        <RouterNavLink to="/profile">
                                            <FontAwesomeIcon icon="user" className="mr-3" /> Profile
                                        </RouterNavLink>
                                    </MenuItem>

                                    {/* Admin link */}
                                    {user['https://gutcheck.svpvc.com/roles']?.includes('admin') && (
                                        <MenuItem className="dropdown-admin has-link">
                                            <RouterNavLink to="/admin" end={false}>
                                                <FontAwesomeIcon icon="lock" className="mr-3" /> Admin
                                            </RouterNavLink>
                                        </MenuItem>
                                    )}

                                    {/* Logout button */}
                                    <MenuItem
                                        id="qsLogoutBtn"
                                        onClick={logoutWithRedirect}
                                        sx={{ padding: '7px 25px' }}
                                    >
                                        <FontAwesomeIcon icon="power-off" className="mr-3" /> Log out
                                    </MenuItem>
                                </Menu>
                            </Box>
                        )}
                    </Stack>

                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
